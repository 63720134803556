<template>
  <div class="card mb-4" v-loading="!customers">
    <div class="card-header">
      <div class="d-md-flex">
        <h3 class="m-0 pr-4 mb-1 flex-grow-1">{{ $t('message.projectInformations') }}</h3>

        <base-button
          size="sm"
          type="primary"
          @click="onSave"
          :disabled="project.state === 'ARCHIVED'"
        >{{ $t('message.save') }}</base-button>
        <base-button size="sm" v-if="!project.template" @click="onSaveAsTemplate(true)" :disabled="project.state === 'ARCHIVED'">{{ $t('message.saveProjectAsTemplate') }}</base-button>
        <base-button size="sm" v-if="project.template" @click="onSaveAsTemplate(false)">{{ $t('message.saveProjectNotTemplate') }}</base-button>
        <base-button size="sm" @click="onCreateSubProject(false)" :disabled="!currentUserRights.includes('project.can-do.subproject')">{{ $t('message.createSubproject') }}</base-button>
        <base-button size="sm" @click="onArchive" :disabled="this.unchecked" v-if="project.state!='ARCHIVED' && currentUserRights.includes('Archiv_Projekte wiederherstellen')">{{ $t('message.archiveProject') }}</base-button>
        <base-button size="sm" @click="onArchive" v-if="project.state === 'ARCHIVED' && currentUserRights.includes('Archiv_Projekte wiederherstellen')">{{ $t('message.unarchiveProject') }}</base-button>
        <base-button size="sm" @click="onCreateOrder" v-if="project.state!='ARCHIVED' /*&& currentUserRights.includes('Navision_Auftrag_erstellen')*/">{{ $t('message.createOrder') }}</base-button>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <base-input
            ref="projectname"
            v-model="$v.project.name.$model"
            :label="$t('message.projectname')"
            :placeholder="$t('message.projectname')"
            :validator="$v.project.name"
            :maxLength="100"
            isFocused
          />
        </div>
        <div class="col-md-3">
          <base-input
            :label="$t('message.projectId')"
            disabled
            :placeholder="$t('message.projectId')"
            v-model="project.key"
          />
        </div>
        <div class="col-md-3 form-control-label">
          {{ $t('message.invoicing') }}
          <div class="mt-2">
            <b-badge v-for="stat in invoiceStatus" :key="stat.number" :variant="stat.status == 'fakturiert' ? 'success' : 'info'" class="mr-2 small">
              {{ stat.status }} ({{ stat.number }})
            </b-badge>
          </div>
          
        </div>
      </div>
      <div class="row">
        <div class="col-9">
            <div class="col-3 d-inline-block form-control-label pl-0 mb-2">{{$t('message.customerId')}}</div>
            <div class="col-3 d-inline-block form-control-label">{{$t('global.order')}}</div>
            <div class="col-2 d-inline-block form-control-label">{{$t('message.costUnit')}}</div>
            <div class="col-3 d-inline-block form-control-label">{{$t('message.description')}}</div>
              <el-select
                class="col-3 pl-0 mb-4"
                v-model="$v.project.customer_no.$model"
                filterable
                remote
                @change="onCustomerChanged"
                :placeholder="$t('message.chooseCustomer')"
                :remote-method="(query) => findCustomers(query, false)"
                :loading="loading.customers"
                :validator="$v.project.customer_no"
              >
                <el-option
                  v-for="option in customers"
                  :key="option.No"
                  :label="option.No + ' ' + option.Name"
                  :value="option.No"
                ></el-option>
              </el-select>
            
              <el-select
                class="col-3"
                v-model="$v.selectedOrders.$model"
                filterable
                remote
                multiple
                :placeholder="$t('message.chooseOrder')"
                :remote-method="findOrders"
                :loading="loading.orders"
                :validator="$v.selectedOrders"
              >
                <el-option
                  v-for="(order, i) in allOrders"
                  :key="i"
                  :label="order.No"
                  :value="order.No"
                ></el-option>
              </el-select>
              <el-input class="col-2" :placeholder="$t('message.costUnit')" disabled :value="kostentraeger" />
              <el-input type="text"
                class="col-3"
                v-model="orderDescription"
                :placeholder="$t('message.description')"
              />
              <span class="ml-3">
                <el-tooltip :content="$t('message.add')" placement="top">
                    <i class="fas fa-plus-circle text-primary"
                      @click="addCustomerAndOrder"></i>
                </el-tooltip>
              </span>

            <div class="error-bar">
              <div class="col-3 d-block invalid-feedback">{{ !$v.project.customer_no.required ? "Bitte geben Sie einen Wert ein." : '' }}</div>
              <div class="col-3 d-block invalid-feedback">{{ !$v.selectedOrders.required ? "Bitte geben Sie einen Wert ein." : '' }}</div>
            </div>

            <div class="additional" v-if="additionalCustomers.length">
              <div class="additionalRow" v-for="(newCustomer, counter) in additionalCustomers" :key="counter">
                <base-input>
                <el-tooltip :content="newCustomer.additional_customer_name ? newCustomer.additional_customer_name : $t('message.customer')" placement="top">
                  <el-select
                    class="col-3 pl-0"
                    :class="'customer'+counter"
                    v-model="newCustomer.additional_customer_no"
                    filterable
                    remote
                    :placeholder="$t('message.chooseCustomer')"
                    :remote-method="(query) => findCustomers(query, true)"
                    :loading="loading.customers"
                    :validator="$v.additionalCustomers.$each"
                  >
                    <el-option
                      v-for="option in moreCustomers"
                      :key="option.No"
                      :label="option.No + ' ' + option.Name"
                      :value="option.No"
                    ></el-option>
                  </el-select>
                </el-tooltip>

                  <el-select
                    class="col-3"
                    v-model="selectedAdditionalOrders[counter]"
                    @change="setNewOrders"
                    filterable
                    remote
                    multiple
                    :placeholder="$t('message.chooseOrder')"
                    :remote-method="(val) => findAdditionalOrders(val, counter)"
                    :loading="loading.orders"
                  >
                    <el-option
                      v-for="(order, i) in additionalOrders"
                      :key="i"
                      :label="order.No"
                      :value="order.No"
                    ></el-option>
                  </el-select>

                  <el-input class="col-2" :placeholder="$t('message.costUnit')" disabled :value="addCostAccount[counter]" />

                  <el-input type="text"
                    class="col-3"
                    v-model="addOrderDescription[counter]"
                    :placeholder="$t('message.description')"
                  />
                  <span class="col-4 text-right">
                    <el-tooltip :content="$t('message.delete')" placement="top">
                        <i class="fas fa-minus-circle"
                        @click="deleteAdditionalCustomer(counter)"></i>
                    </el-tooltip>
                  </span>
                </base-input>

                <div class="error-bar">
                  <div class="col-3 d-block invalid-feedback">{{ !$v.additionalCustomers.$each[counter].validCustomer ? "Bitte geben Sie einen Wert ein." : '' }}</div>
                  <div class="col-3 d-block invalid-feedback"></div>
                </div>

              </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <base-input v-if="project.pt_sync_enabled"
                      v-model="pt_number"
                      :label="$t('message.PTNumberRange')"
                      :placeholder="$t('message.PTNumberRange')"
                      disabled>
          </base-input>
          <base-input v-if="!project.pt_sync_enabled"
                      :label="$t('message.PTNumberRange')"
                      :placeholder="$t('message.PTNumberRange')">
                      <tags-input v-model="pt_numbers" :placeholder="$t('message.PTNumberRange')"/>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            v-model="project.pt_ordertext"
            :label="$t('message.PTOrdertext')"
            :placeholder="$t('message.PTOrdertext')"
            :validator="$v.project.pt_ordertext"
            :maxLength="50"
          />
          <base-input :label="$t('message.functions')" placeholder="">
            <el-select multiple class="select-primary" v-model="functions" :placeholder="$t('message.functions')">
              <el-option
                v-for="option in allFunctions"
                class="select-primary"
                :value="option"
                :label="$t(`project.functions.${option}`)"
                :key="option"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input :label="$t('message.description')">
            <html-editor v-model="project.description"></html-editor>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-checkbox v-model="project.is_private">{{ $t('project.is_private') }}</base-checkbox>
        </div>
        <div class="col-md-6">
          <base-checkbox v-model="project.pt_sync_enabled">{{ $t('project.pt_sync_enabled') }}</base-checkbox>
        </div>

      </div>
    </div>

    <order-form
      :project="project"
      documentType="Order"
      :show.sync="showNewOrder"
      @changed="onNewOrder"
    />

    <sub-project-form
      :project="project"
      :show.sync="showCreateSubProject"
      @changed="onNewOrder"
    />

  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import TagsInput from "../../../../components/Inputs/TagsInput";
import OrderForm from "./OrderForm";
import SubProjectForm from "./SubProjectForm";
import { BBadge } from 'bootstrap-vue';

export default {
  name: "projectDetails",
  components: {
    TagsInput,
    HtmlEditor,
    OrderForm,
    SubProjectForm,
    BBadge
  },
  props: ["project"],
  data() {
    return {
      loading: {
        customers: false,
        orders: false,
      },
      additional: [],
      customers: [],
      allOrders: [],
      tempOrders: [],
      allFunctions: ['D', 'B', 'A', 'M', 'E', 'K', 'L', 'U', 'I', 'T', 'R', 'C'],
      data: {
        laden: null,
        abfahrt: null
      },
      configs: {
        dateTimePicker: {
          enableTime: true,
          dateFormat: "d-m-Y H:i",
          time_24hr: true
        }
      },
      pt_numbers: [],
      kostentraeger: "",
      showNewOrder: false,
      showCreateSubProject: false,
      unchecked: false,
      moreCustomers: [],
      additionalCustomers: [],
      additionalOrders: [],
      allAdditionalOrders: [],
      tempAdditionalOrders: [],
      newCustomerOrders: [],
      selectedCustomer: "",
      orderDescription: null,
      addOrderDescription: [],
      addCostAccount: []
    };
  },
  validations() {
    return {
      selectedOrders: {
        required
      },
      project: {
        name: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(100)
        },
        key: {
        },
        customer_no: {
          required
        },
        pt_ordertext: {
          required
        },
      },
      additionalCustomers: {
        $each: {
          validCustomer: (value) => value.additional_customer_no == "" ? false : true
        }
      }
    }
  },
  watch: {
    async project() {
      if (this.project.customer_no) {
        const response = await this.$http.get('dyn/customers', {params: {q: this.project.customer_no}});
        this.customers = response.body;
        this.pt_numbers = this.project.pt_number ? this.project.pt_number.split(',') : [];
        this.orderDescription = this.project_orders.filter(o => o.additional_customer_no == null).map(order => order.description)[0];
      }
    },
    'project_orders' () {
      // Fallback: When an order has not been synched from Dyn
      this.allOrders = this.project_orders.filter(o => o.additional_customer_no == null).map(order => order.order || {No: order.order_no});
      this.tempOrders = this.project_orders.filter(o => o.additional_customer_no == null).map(order => order.order_no);
      this.allAdditionalOrders = this.project_orders.filter(o => o.additional_customer_no != null).map(order => order.order || {No: order.order_no});
      let tempAdditionals = this.project_orders.filter(o => o.additional_customer_no != null)
      if (tempAdditionals.length > 0) {
        let additionals = []
        let addCus = []
        let addOrd = []
        let addDes = []

        tempAdditionals.forEach((item) => {
          let found = additionals.filter(t => {
                                if(t.additional_customer_no == item['additional_customer_no'] && t.ongoing == item['ongoing_no']) {
                                  t.add_orders.push({
                                                      num: item['order_no'],
                                                      order: item.order
                                                    })
                                  return true;
                                }
                                return false;
                              })

          if(found == false) {
            additionals.push({
                              c_name: item.order.Sell_to_Customer_Name,
                              add_orders: [{
                                            num: item['order_no'],
                                            order: item.order
                                          }],
                              additional_customer_no: item['additional_customer_no'],
                              des: item['description'],
                              ongoing: item['ongoing_no']
                            })
          }
        })
        
        Object.entries(additionals).forEach((add) => {
          const [key, value] = add;
          addCus.push({
            additional_customer_no: value.additional_customer_no,
            additional_customer_name: value.c_name,
            ongoing: value.ongoing
          });
          addOrd.push(value.add_orders)
          addDes.push(value.des)
        })
        this.additionalCustomers = addCus;
        this.tempAdditionalOrders = addOrd;
        this.addOrderDescription = addDes;
      }
    },
    pt_numbers() {
      this.project.pt_number = this.pt_numbers.join(',')
    },
    allOrders() {
      this.kostentraeger = this.allOrders
        .filter(order => this.tempOrders.indexOf(order.No)>=0)
        .map(order => order.Shortcut_Dimension_2_Code)
        .filter((v,i,a)=>a.indexOf(v)==i)
        .join(', ')
    },
    tempOrders() {
      this.kostentraeger = this.allOrders
        .filter(order => this.tempOrders.indexOf(order.No)>=0)
        .map(order => order.Shortcut_Dimension_2_Code)
        .filter((v,i,a)=>a.indexOf(v)==i)
        .join(', ')
    },
    tempAdditionalOrders() {
      this.tempAdditionalOrders.forEach((item) => {
        let numbers = this.allAdditionalOrders
          .filter(order => item.map(n => n.num).indexOf(order.No)>=0)
          .map(order => order.Shortcut_Dimension_2_Code)
          .filter((v,i,a)=>a.indexOf(v)==i)
          .join(', ')
        if(this.tempAdditionalOrders.length > this.addCostAccount.length) {
          this.addCostAccount.push(numbers)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      "locations",
      "project_orders",
      "currentUserRights",
      "pickingListPositions",
      "loadingListPositions",
      "reStrorageListPositions"
    ]),
    nameValidationError() {
      if (!this.$v.project.name.required) {
        return "fehlt";
      } else if (!this.$v.project.name.minLength) {
        return "zu kurz";
      }
    },
    selectedOrders: {
      get () {
        return this.tempOrders
      },
      set (values) {
        this.tempOrders = values;
        this.$set(this.project, 'orders', this.tempOrders)
      }
    },
    selectedAdditionalOrders: {
      get () {
        let nums = []
        this.tempAdditionalOrders.forEach((item) => {
          let num = []
          item.forEach((n) => {
            num.push(n.num)
          })
          nums.push(num)
        })
        return nums
      },
    },
    functions: {
      get() {
        return this.project.functions.split("");
      },
      set(value) {
        this.project.functions = value.join("");
      }
    },
    invoiceStatus() {
      let projectOrders = [...this.allOrders, ...this.allAdditionalOrders];
      
      let invoices = projectOrders
                    .filter(order => this.selectedOrders.indexOf(order.No)>=0 && order.StatusInvoice !== null && order.StatusInvoice != '_blank_')
                    .map(order => ({status: order.StatusInvoice, number: order.No}))
      
      this.updateInvoiceStatus(projectOrders);

      return invoices
    },
    pt_number() {
      if( this.project.key ) {
        return '1' + this.project.key;
      } else {
        return;
      }
    }
  },
  methods: {
    ...mapActions([
      "loadAdditional",
      "loadOrderByNo"
    ]),
    setNewOrders() {
      this.additionalOrders = [];
    },
    addCustomerAndOrder() {
      let numbers = this.additionalCustomers.map(i => {
        if(i.ongoing != null) {
          return i.ongoing
        }
        return 0
      })
      
      let next_ongoing = Math.max(...numbers) +1
      
      this.additionalCustomers.push({
        additional_customer_no: '',
        ongoing: next_ongoing
      });
      this.additionalOrders = [];
    },
    deleteAdditionalCustomer(counter) {
      this.additionalCustomers.splice(counter,1);
      this.selectedAdditionalOrders.splice(counter,1);
      this.addOrderDescription.splice(counter,1);
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.projectname.$el.querySelector('input').focus();
      })
    },
    async loadAdditionalMaterial(project_id) {
      this.additional = await this.loadAdditional(project_id);
      this.materialComments();
    },
    async findCustomers(query, additional) {
      this.loading.customers = true;
      const response = await this.$http.get('dyn/customers', {params: {q: query}});
      if (additional) {
        this.moreCustomers = response.body;
      } else {
        this.customers = response.body;
      }
      this.loading.customers = false;
    },
    async findOrders(query) {
      this.loading.orders = true;
      this.allOrders = [];
      const customers = [this.project.customer_no]

      for (let i=0; i<this.locations.length; i++) {
        const address = this.locations[i]
        if (address.customer_no) {
          customers.push(address.customer_no)
        }
      }

      for (let i=0; i<customers.length; i++) {
        const customer_no = customers[i]
        const response = await this.$http.get('dyn/orders/'+customer_no, {params: {q: query}});
        const orders = response.body
        this.allOrders.push(...orders)
      }
      this.loading.orders = false;
    },
    async findAdditionalOrders(query, counter) {
      this.loading.orders = true;
      this.additionalOrders = [];
      const row = document.querySelectorAll('.customer'+counter+' input');
      const val = row[0].value;
      const customers = []
      if (val) {
        const c_nr = val.split(' ', 1);
        customers.push(c_nr);
      }

      for (let i=0; i<customers.length; i++) {
        const customer_no = customers[i]
        const response = await this.$http.get('dyn/orders/'+customer_no, {params: {q: query}});
        const orders = response.body
        this.additionalOrders.push(...orders)
      }
      this.loading.orders = false;
    },
    async updateInvoiceStatus(projectOrders) {
      let invoices = [];

      if (projectOrders.length) {
        let orderNumbers = projectOrders.map(order => order.No);
        let singleOrders = [];
        
        await orderNumbers.reduce(async (promise, item) => {
          await promise;
          const orders = await this.$http.get('dyn/order/'+item, {})
          singleOrders.push(orders.body);
        }, Promise.resolve());

        invoices = singleOrders
                      .filter(order => orderNumbers.indexOf(order.No)>=0 && order.StatusInvoice !== null && order.StatusInvoice != '_blank_')
                      .map(order => ({status: order.StatusInvoice, number: order.No}))
      }

      if (invoices.length) {
        for(let item of invoices) {
          let savedOrder = await this.loadOrderByNo(item.number);
          if (savedOrder.length > 0) {
            this.$store.dispatch('updateOrder', {id: savedOrder[0].id, data: {StatusInvoice: item.status}});
          }
        }
      }
    },
    onArchive() {
      this.$emit("archive", this.project);
    },
    onCustomerChanged(val) {
      this.selectedCustomer = val;
      this.project.orders = [];
    },
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("Validation failed", this.$v);
        return
      }
      let additionals = []
      if (this.additionalCustomers.length > 0) {
        this.additionalCustomers.forEach((item, i) => {
          additionals.push({
            customer_no: item.additional_customer_no,
            orders: this.selectedAdditionalOrders[i],
            description: this.addOrderDescription[i],
            ongoing: item.ongoing
          })
        })
      }
      this.$emit("save", this.project, additionals);
    },
    onSaveAsTemplate(tmpl) {
      this.$emit("template", this.project, tmpl);
    },
    onCreateOrder() {
      this.showNewOrder = true
    },
    onNewOrder(document) {
      const orders = this.selectedOrders
      orders.push(document.No)
      this.selectedOrders = orders
    },
    onCreateSubProject () {
      this.showCreateSubProject = true
    },
    materialComments() {
      const items = this.pickingListPositions.concat(this.additional);

      let uncheckedPickinglist = items.filter(
        i => i.komm_bemerkung && i.komm_bemerkung_checked === null
      )
      let uncheckedLoadinglist = items.filter(
        i => i.lade_bemerkung && i.lade_bemerkung_checked === null
      )
      let uncheckedRestoragelist = items.filter(
        i => i.rueck_bemerkung && i.rueck_bemerkung_checked === null
      )

      const uncheckedComments = [...uncheckedPickinglist, ...uncheckedLoadinglist, ...uncheckedRestoragelist];
      if (uncheckedComments.length > 0) {
        this.unchecked = true;
      }
    }
  },
  async mounted() {
    this.focusInput();
    if (this.$route.params.id) {
      this.loadAdditionalMaterial(this.$route.params.id);
    }
  }

};
</script>
<style>
.el-input.is-disabled .el-input__inner {
  background-color: #e1e4e8;
  border-color: #d1d5da;
  color: #586069;
}
.error-bar {
  display: flex;
  margin-top: -25px;
  margin-bottom: 1.5rem;
}
</style>